import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0,2,5];

export const dictionary = {
		"/": [7],
		"/(app)/dashboard": [8,[2,3]],
		"/(auth)/login": [10,[5]],
		"/(app)/profile": [9,[2,4]],
		"/(auth)/restore": [11,[5]],
		"/sentry-example": [14],
		"/(auth)/signup": [12,[5]],
		"/(auth)/verify-email": [13,[5,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';